.stroke-text {
	--stroke-color: rgb(225 160 7);
	--stroke-width: 1.5px;

	letter-spacing: calc(3.5 * var(--stroke-width));
	color: #f5f5f5;
	text-shadow: var(--stroke-width) 0 0 var(--stroke-color),
		calc(var(--stroke-width) * -1) 0 0 var(--stroke-color),
		0 var(--stroke-width) 0 var(--stroke-color),
		0 calc(var(--stroke-width) * -1) 0 var(--stroke-color);
}

.smooth-16 {
	text-shadow: calc(var(--stroke-width) * 1) calc(var(--stroke-width) * 0) 0
		var(--stroke-color),
		calc(var(--stroke-width) * 0.9239) calc(var(--stroke-width) * 0.3827) 0
		var(--stroke-color),
		calc(var(--stroke-width) * 0.7071) calc(var(--stroke-width) * 0.7071) 0
		var(--stroke-color),
		calc(var(--stroke-width) * 0.3827) calc(var(--stroke-width) * 0.9239) 0
		var(--stroke-color),
		calc(var(--stroke-width) * 0) calc(var(--stroke-width) * 1) 0 var(--stroke-color),
		calc(var(--stroke-width) * -0.3827) calc(var(--stroke-width) * 0.9239) 0
		var(--stroke-color),
		calc(var(--stroke-width) * -0.7071) calc(var(--stroke-width) * 0.7071) 0
		var(--stroke-color),
		calc(var(--stroke-width) * -0.9239) calc(var(--stroke-width) * 0.3827) 0
		var(--stroke-color),
		calc(var(--stroke-width) * -1) calc(var(--stroke-width) * 0) 0
		var(--stroke-color),
		calc(var(--stroke-width) * -0.9239) calc(var(--stroke-width) * -0.3827) 0
		var(--stroke-color),
		calc(var(--stroke-width) * -0.7071) calc(var(--stroke-width) * -0.7071) 0
		var(--stroke-color),
		calc(var(--stroke-width) * -0.3827) calc(var(--stroke-width) * -0.9239) 0
		var(--stroke-color),
		calc(var(--stroke-width) * 0) calc(var(--stroke-width) * -1) 0
		var(--stroke-color),
		calc(var(--stroke-width) * 0.3827) calc(var(--stroke-width) * -0.9239) 0
		var(--stroke-color),
		calc(var(--stroke-width) * 0.7071) calc(var(--stroke-width) * -0.7071) 0
		var(--stroke-color),
		calc(var(--stroke-width) * 0.9239) calc(var(--stroke-width) * -0.3827) 0
		var(--stroke-color);
}
